<template>
  <b-card>
    <b-card-title
      class="text-primary d-flex align-items-center justify-content-between"
    >
      <span>
        Options
      </span>
      <b-button
        variant="primary"
        @click="openOptionForm()"
      >
        Add option
      </b-button>
    </b-card-title>
    <b-card-body>
      <div>
        <div
          v-for="(option,i) in optionList"
          :key="option.id"
        >
          <div
            class="d-flex align-items-center justify-content-between mb-1"
          >
            <div>
              <h6>{{ option.name }}</h6>
            </div>
            <div>
              <b-button
                variant="outline-primary"
                size="sm"
                @click="openOptionForm(option,'sub-option')"
              >
                Add Sub-option
              </b-button>
              <b-button
                variant="primary"
                class="mx-2"
                size="sm"
                @click="openOptionForm(option)"
              >
                Edit Option {{ i+1 }}
              </b-button>
            </div>
          </div>
          <b-table
            id="reportTable"
            :items="option.child_options"
            primary-key="id"
            responsive
            show-empty
            :fields="tableColumns"
            class="position-relative"
          >
            <template #cell(actions)="data">
              <b-badge
                pill
                variant="light-primary"
                class="cursor-pointer"
                @click="getSubOptionData(data.item,option)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  size="16"
                />
              </b-badge>
            </template>
          </b-table>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'OptionList',
  props: {
    optionList: {
      type: Array,
    },
  },
  data() {
    return {
      tableColumns: [
        {
          key: 'name', label: 'Sub-Option Name', thClass: 'md',
        },
        {
          key: 'capacity', label: 'Capacity', thClass: 'md',
        },
        {
          key: 'base_price', label: 'Base Price', thClass: 'md',
        },
        { key: 'actions', label: 'Action' },
      ],
    }
  },
  methods: {
    openOptionForm(option, formType) {
      this.$emit('openOptionForm', option, formType)
    },
    getSubOptionData(subOption, option) {
      this.$emit('getSubOptionData', subOption, option)
    },
  },
}
</script>
