<template>
  <b-card class="w-100 facilities">
    <b-card-title>
      {{ !parentId ?'Option':'Sub Option' }}
    </b-card-title>
    <b-card-sub-title>
      This section is for the facilities that are available in your property. These facilities will be seen by guests when they search for a place to stay.
    </b-card-sub-title>
    <b-card-body>
      <!-- Basic Input-->
      <b-row
        class="my-1 service-charge border-bottom"
      >
        <!-- Name -->
        <b-col
          md="6"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-input-group>
              <b-form-input
                id="name"
                v-model="optionData.name"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Capacity -->
        <b-col md="6">
          <b-form-group
            label="Capacity"
            label-for="capacity"
          >
            <b-input-group>
              <b-form-input
                id="capacity"
                v-model="optionData.capacity"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Base Price -->
        <b-col md="6">
          <b-form-group
            label="Base Price"
            label-for="base-price"
          >
            <b-input-group
              prepend="BDT"
            >
              <b-form-input
                id="base-price"
                v-model="optionData.base_price"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Child Base Price-->
        <b-col
          md="6"
        >
          <b-form-group
            label="Child Base Price"
            label-for="child-base-price"
          >
            <b-input-group
              prepend="BDT"
            >
              <b-form-input
                id="child-base-price"
                v-model="optionData.base_price_child"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Category -->
        <b-col
          v-if="!parentId"
          md="6"
        >
          <b-form-group
            label="Category"
            label-for="category"
          >
            <v-select
              id="category"
              v-model="optionData.category"
              :options="categoryList"
              label="display_name"
            />
          </b-form-group>
        </b-col>
        <!-- Subcategory Text-->
        <template v-if="optionData.category">
          <b-col
            v-if="!parentId && isObjectEmpty(optionData.category)"
            md="6"
          >
            <p class="subcategory-text text-success m-0 font-weight-bold">
              <feather-icon
                :icon="optionData.category.child_unit_type? 'CheckIcon': 'XIcon'"
                size="16"
                class="align-middle text-body"
              />
              SubOption
            </p>
            <p class="m-0">
              {{ optionData.category.parent_unit_type ==='IGNORE' ? 'Option will be ignored': `Option will be based on ${optionData.category.parent_unit_type}` }}
            </p>
            <p class="m-0">
              {{ optionData.category.has_child ? (optionData.category.child_unit_type ==='IGNORE' ? 'Sub Option will be ignored': `Sub Option will be based on ${optionData.category.child_unit_type}`) : null }}
            </p>
          </b-col>
        </template>
        <!-- Descriptoin -->
        <b-col
          md="12"
        >
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-input-group>
              <b-form-textarea
                id="description"
                v-model="optionData.description"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        class="my-1 service-charge border-bottom"
      >
        <!-- Addon List -->
        <b-col
          md="6"
        >
          <h6 class="text-bold mb-1 text-primary">
            Add-ons
          </h6>
          <b-form-group
            label="Select Addons"
            label-for="addons"
          >
            <vue-autosuggest
              v-model="addonQuery"
              :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Search..'}"
              :suggestions="[{ data: addonsList}]"
              :limit="10"
              @focus.once="debounceSearch(0, getAddonList)"
              @input="debounceSearch(0, getAddonList)"
              @selected="getSelectedAddon"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </b-form-group>
        </b-col>
        <!-- Addon List Table -->
        <b-col md="12">
          <b-table
            class=""
            responsive
            :fields="addonsTableCol"
            :items="optionData.add_on"
            show-empty
            empty-text="No records found"
          >
            <template #cell(actions)="data">
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="cursor-pointer text-danger"
                @click="deleteAddon(data.item.id)"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>
      <!-- Pickup Time List -->
      <b-row class="my-1 border-bottom">
        <b-col
          md="6"
        >
          <h6 class="text-bold mb-1 text-primary">
            Pick-up
          </h6>
          <b-form-group
            label="Select Pickup"
            label-for="pickuptime"
          >
            <vue-autosuggest
              v-model="pickupTimeQuery"
              :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Search..'}"
              :suggestions="[{ data: pickupTimeList}]"
              :limit="10"
              @focus.once="debounceSearch(0, getPickupTimeList)"
              @input="debounceSearch(0, getPickupTimeList)"
              @selected="getSelectedPickupTime"
            >
              <template slot-scope="{suggestion}">
                <span class="suggestion-text">{{ suggestion.item.text }}</span>
              </template>
            </vue-autosuggest>
          </b-form-group>
        </b-col>
        <!-- Pickup Times Table-->
        <b-col md="12">
          <b-table
            responsive
            :fields="pickupTimeTableCol"
            :items="optionData.pick_up"
            show-empty
            empty-text="No records found"
          >
            <template #cell(actions)="data">
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="cursor-pointer text-danger"
                @click="deletePickupTime(data.item.id)"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>
      <!-- Image -->
      <b-row class="my-1 border-bottom">
        <b-col md="6">
          <h6 class="text-bold mb-1 text-primary">
            Pictures
          </h6>
          <b-form-group
            label="Upload Pictures"
            label-for="pictures"
          >
            <b-form-file
              id="pictures"
              v-model="imageFiles"
              accept="image/jpeg, image/png, image/jpg"
              multiple
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="uploadImageFiles"
            />
          </b-form-group>
        </b-col>
        <!-- Image Preview -->
        <b-col md="12">
          <div class="mb-2">
            <draggable
              v-model="optionData.pictures"
              class="d-flex"
              @change="changeImageOrder($event, option.pictures)"
            >
              <div
                v-for="(img,i) in optionData.pictures"
                :key="img.id"
                class="img-box mr-1  p-1 border"
              >
                <feather-icon
                  icon="XIcon"
                  size="12"
                  class="close-icon"
                  @click="removeImage(img,i)"
                />
                <b-img
                  :src="img.img"
                  alt="option image"
                  class="w-100 h-100"
                />
              </div>
            </draggable>
          </div>
        </b-col>
      </b-row>
      <!-- Multiplicable -->
      <b-row>
        <b-col
          md="6"
        >
          <b-form-group
            label="Multiplicable"
            label-for="multiplicable"
          >
            <b-form-radio-group
              id="multiplicable"
              v-model="optionData.is_multiplicable"
              :options="multiplicableOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Submit -->
      <b-row
        align-h="between"
        class="facility-names mb-2"
      />
      <div class="text-right">
        <b-button
          variant="outline-primary"
          class="mx-1"
          @click="closeOptionForm"
        >
          Close
        </b-button>
        <b-button
          variant="primary"
          :disabled="isBtnLoading"
          @click="createOrUpdateOption"
        >
          <b-spinner
            v-if="isBtnLoading"
            small
          />
          Submit
        </b-button>
      </div>
    </b-card-body>
    <div
      class="text-right p-2 border-top"
    >
      <b-button
        variant="outline-primary"
        :to="`/property-register/step-3/${'tourId'}`"
      >
        Photos
        <feather-icon
          icon="ArrowRightIcon"
          size="12"
        />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import draggable from 'vuedraggable'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import tour from '@/mixins/tour'

export default {
  name: 'OptionForm',
  components: {
    VueAutosuggest,
    draggable,
  },
  mixins: [helper, tour],
  props: {
    categoryList: {
      type: Array,
    },
    optionInfo: {
      type: Object,
    },
    tourId: {
      type: Number,
    },
    parentId: {
      type: Number,
    },
  },
  data() {
    return {
      optionData: {},
      addonsList: [],
      pickupTimeList: [],
      isBtnLoading: false,
      addonQuery: '',
      pickupTimeQuery: '',
      imageFiles: [],
      optionImages: [],
      multiplicableOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      addonsTableCol: [
        {
          key: 'name', thClass: 'md',
        },
        {
          key: 'max_capacity', label: 'capacity', thClass: 'md',
        },
        {
          key: 'unit', thClass: 'md',
        },
        {
          key: 'price', thClass: 'md',
        },
        { key: 'actions', label: '' },
      ],
      pickupTimeTableCol: [
        {
          key: 'text', thClass: 'md',
        },
        {
          key: 'price', thClass: 'md',
        },
        {
          key: 'time', thClass: 'md',
        },
        { key: 'actions', label: '' },
      ],
    }
  },
  mounted() {
    if (this.isObjectEmpty(this.optionInfo)) {
      this.optionData = { ...this.optionInfo }
    } else {
      this.optionData = {
        add_on: [], pick_up: [], pictures: [], category: { },
      }
    }
  },
  methods: {
    getAddonList() {
      const search = this.addonQuery ? `?search=${this.addonQuery}` : ''
      api.getData(`tour/admin_services/addons_list/${search}/`, true)
        .then(response => {
          if (response.data.status) {
            this.addonsList = response.data.result.results
          }
        }).catch(() => {
        })
    },
    getSelectedAddon(data) {
      this.addonQuery = data.item.name
      this.optionData.add_on.push(data.item)
    },
    getPickupTimeList() {
      const search = this.pickupTimeQuery ? `?search=${this.pickupTimeQuery}` : ''
      api.getData(`tour/admin_services/pickup_time_list/${search}/`, true)
        .then(response => {
          if (response.data.status) {
            this.pickupTimeList = response.data.result.results
          }
        }).catch(() => {
        })
    },
    getSelectedPickupTime(data) {
      this.pickupTimeQuery = data.item.text
      this.optionData.pick_up.push(data.item)
    },
    closeOptionForm() {
      this.$emit('closeOptionForm')
    },
    deleteAddon(id) {
      this.optionData.add_on = this.optionData.add_on.filter(addon => addon.id !== id)
    },
    deletePickupTime(id) {
      this.optionData.pick_up = this.optionData.pick_up.filter(pu => pu.id !== id)
    },
    uploadImageFiles() {
      const maxIndex = this.optionData.pictures.length ? this.optionData.pictures.length : 1
      this.imageFiles.forEach((file, i) => {
        const formData = new FormData()
        formData.append('img', file)
        formData.append('p_index', maxIndex + i)

        api.postData('tour/admin_services/tour_picture/', true, formData).then(res => {
          this.optionData.pictures.push(res.data.result)
        })
      })
    },
    removeImage(image, index) {
      const formData = {
        id: image.id,
        p_index: image.p_index,
      }
      api.updateData(`tour/admin_services/tour_picture/${image.id}/?delete_old=true`, true, formData).then(response => {
        if (response.data.status) {
          this.optionData.pictures.splice(index, 1)
          this.createOrUpdateOption()
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Picture removed successfully')
          this.$emit('getTourData')
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      })
    },
    prepareOptionData() {
      const formData = { ...this.optionData }
      formData.pictures = this.optionData.pictures.map(picture => picture.id)
      formData.add_on = this.optionData.add_on.map(addon => addon.id)
      formData.pick_up = this.optionData.pick_up.map(pickUp => pickUp.id)
      if (this.parentId) {
        delete formData.category
        formData.parent = this.parentId
      }
      if (!this.parentId) {
        formData.category = this.optionData.category && this.optionData.category.id
          ? this.optionData.category.id : this.optionData.category
      }
      const optionId = formData.id
      return [optionId, formData]
    },
    createOrUpdateOption() {
      this.isBtnLoading = true
      let url = 'tour/admin_services/option_create/'
      let apiResponse
      const [optionId, formData] = this.prepareOptionData()
      if (optionId) {
        url = `tour/admin_services/option_update/${optionId}/`
        apiResponse = api.updateData(url, true, formData)
      } else {
        formData.tour = this.tourId
        apiResponse = api.postData(url, true, formData)
      }
      apiResponse.then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', `option ${optionId ? 'updated' : 'created'} successfully`)
          this.$emit('getTourData')
          this.closeOptionForm()
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@/assets/scss/tour.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style lang="scss" scoped>
.subcategory-text svg{
stroke: var(--green);
}
.img-box {
  width: 150px;
  height: 150px;
  position: relative;
  .close-icon {
  cursor: pointer;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #eceaff;
  padding: 5px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  }
}
</style>
