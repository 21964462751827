<template>
  <div
    v-if="showForm"
  >
    <OptionForm
      v-if="showForm"
      :category-list="categoryList"
      :option-info="optionInfo"
      :tour-id="tourId"
      :parent-id="optionParent"
      @getTourData="getTourData"
      @closeOptionForm="closeOptionForm"
    />
  </div>
  <div v-else>
    <OptionList
      :option-list="optionList"
      @openOptionForm="openOptionForm"
      @getSubOptionData="getSubOptionData"
    />
  </div>
</template>

<script>
import OptionForm from './OptionForm.vue'
import OptionList from './OptionList.vue'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'Options',
  components: {
    OptionForm,
    OptionList,
  },
  mixins: [helper],
  data() {
    return {
      categoryList: [],
      optionList: [],
      showForm: false,
      optionInfo: {},
      tourId: '',
      optionParent: null,
    }
  },
  mounted() {
    this.getTourData()
    this.getAvailableCategoryList()
  },
  methods: {
    getAvailableCategoryList() {
      api.getData('tour/admin_services/availability_category_list/', true)
        .then(response => {
          if (response.data.status) {
            this.categoryList = response.data.result.results
          }
        }).catch().finally(() => {
        })
    },
    getTourData() {
      api.getData(`tour/admin_services/tour/${this.$route.params.id}/`, true)
        .then(response => {
          if (response.data.status) {
            this.tourId = response.data.result.id
            this.optionList = response.data.result.all_options
          }
        }).catch().finally(() => {
        })
    },
    openOptionForm(option, formType) {
      if (formType === 'sub-option') {
        this.optionParent = option.id
        this.optionInfo = {}
      } else {
        this.optionParent = null
      }
      if (option && formType !== 'sub-option') {
        this.optionInfo = option
      }
      this.showForm = true
    },
    getSubOptionData(subOption, option) {
      this.optionInfo = subOption
      this.optionParent = option.id
      this.showForm = true
    },
    closeOptionForm() {
      this.optionInfo = {}
      this.showForm = false
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
